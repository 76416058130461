import { KycStatus } from '@shared/api/types/user';

export const getKycStatusFlags = (
  status?: KycStatus,
): {
  hasVerifiedKyc: boolean;
  isNeedVerification: boolean;
  isError: boolean;
  isPending: boolean;
} => {
  const needVerificationStatuses: KycStatus[] = [
    KycStatus.INIT,
    KycStatus.PENDING,
    KycStatus.DELETED,
    KycStatus.REJECTED_RETRY,
  ];
  const successStatuses: KycStatus[] = [KycStatus.COMPLETED];
  const errorStatuses: KycStatus[] = [KycStatus.REJECTED_FINAL];
  const isPending = status === KycStatus.PENDING;

  if (!status || needVerificationStatuses.includes(status)) {
    return {
      hasVerifiedKyc: false,
      isNeedVerification: true,
      isError: false,
      isPending,
    };
  }

  if (successStatuses.includes(status)) {
    return {
      hasVerifiedKyc: true,
      isNeedVerification: false,
      isError: false,
      isPending,
    };
  }

  if (errorStatuses.includes(status)) {
    return {
      hasVerifiedKyc: false,
      isNeedVerification: false,
      isError: true,
      isPending,
    };
  }

  return {
    hasVerifiedKyc: false,
    isNeedVerification: false,
    isError: false,
    isPending,
  };
};

export const kycStatusLabels: Record<KycStatus, string> = {
  [KycStatus.INIT]: 'Get Verified',
  [KycStatus.PENDING]: 'Verification in progress',
  [KycStatus.COMPLETED]: 'Verified',
  [KycStatus.DELETED]: 'Verification data removed',
  [KycStatus.REJECTED_RETRY]: 'Please retry verification',
  [KycStatus.REJECTED_FINAL]: 'Unable to verify',
};
