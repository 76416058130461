import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getNormalizedMixpanelToggles, NormalizedMixpanelToggles } from '@shared/api/mixPanel';

export const mixPanelTogglesQueryKey = {
  server: ['mixPanelToggles'],
};

export const prefetchMixPanelTogglesQuery = async (clientQuery: QueryClient) => {
  await clientQuery.prefetchQuery({
    queryKey: mixPanelTogglesQueryKey.server,
    queryFn: () => getNormalizedMixpanelToggles(),
  });

  return clientQuery.getQueryData<NormalizedMixpanelToggles>(mixPanelTogglesQueryKey.server);
};

export const useMinPanelTogglesQuery = (options?: UseQueryOptions<NormalizedMixpanelToggles>) => {
  return useQuery({
    queryKey: mixPanelTogglesQueryKey.server,
    queryFn: () => getNormalizedMixpanelToggles(),
    ...options,
  });
};
