import React from 'react';

import { ExternalLink } from '@uikit/components/ExternalLink';
import { ModalCloseButton, ModalContent, ModalHeading } from '@uikit/components/Modal';
import ModalDescription from '@uikit/components/Modal/ModalDescription';

import { useCustomContentModal } from '@shared/common/providers/GlobalModalsProvider';
import useTelegramMiniAppSdk from '@shared/hooks/useTelegramMiniAppSdk';

const useTmaRestrictionModal = () => {
  const modal = useCustomContentModal();
  const { isAppOpenedInTelegram } = useTelegramMiniAppSdk();
  const launchpadUrl = 'https://launchpad.magicsquare.io/';

  const open = () =>
    modal.open({
      content: (
        <ModalContent className="flex flex-col items-center p-6 sm:px-12  w-full max-w-[472px] text-center">
          <ModalCloseButton className="!mb-4" />
          <ModalHeading className="mb-4">
            This feature is available exclusively on web version of Magic Launchpad.
          </ModalHeading>

          <ModalDescription className="font-light text-sm text-neutral-500 mb-4">
            To access it, please visit (
            <ExternalLink className="text-secondary" href={launchpadUrl}>
              {launchpadUrl}
            </ExternalLink>
            ).
          </ModalDescription>

          <button className="button button-solid-secondary" onClick={modal.close}>
            OK
          </button>
        </ModalContent>
      ),
    });

  return {
    tmaRestrictionModal: {
      ...modal,
      open,
    },
    isAppOpenedInTelegram,
  };
};

export default useTmaRestrictionModal;
