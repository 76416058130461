import { useQuery } from '@tanstack/react-query';

import { getHeaderBanners } from '@shared/api/headerBanner';

export const useHeaderBannerQuery = ({
  options,
}: {
  options?: { enabled?: boolean };
} = {}) => {
  return useQuery({
    queryKey: ['headerBanner'],
    queryFn: () => getHeaderBanners({ limit: 1 }),
    select: (data) => data[0],
    ...options,
  });
};
