import { useCallback } from 'react';

import { MixpanelEventName } from '@shared/api/mixPanel';
import { useMinPanelTogglesQuery } from '@shared/hooks/query/mixPanel';

const useMixPanelToggles = () => {
  const { data } = useMinPanelTogglesQuery();

  const getIsMixPanelEventEnabled = useCallback(
    (eventName: MixpanelEventName) => {
      if (!data) {
        return false;
      }

      // If data fetched AND there is no target event - defaults to ENABLED (true)
      return data[eventName] ?? true;
    },
    [data],
  );

  return {
    getIsMixPanelEventEnabled,
  };
};

export default useMixPanelToggles;
