import { SVGProps, useId } from 'react';

export const Attention = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 16 16" {...props} color="currentColor">
      <g clipPath={`url(#${gradientId})`}>
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.995 3.6001C8.49206 3.6001 8.895 4.00304 8.895 4.5001V8.0001C8.895 8.49715 8.49206 8.9001 7.995 8.9001C7.49794 8.9001 7.095 8.49715 7.095 8.0001V4.5001C7.095 4.00304 7.49794 3.6001 7.995 3.6001ZM7.095 11.5001C7.095 11.003 7.49794 10.6001 7.995 10.6001H8.005C8.50206 10.6001 8.905 11.003 8.905 11.5001C8.905 11.9972 8.50206 12.4001 8.005 12.4001H7.995C7.49794 12.4001 7.095 11.9972 7.095 11.5001Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2786_102034">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
