import React, { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';

// NOTE:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noopener
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel/noreferrer
// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel#nofollow
export const ExternalLink = forwardRef(
  (props: ComponentPropsWithoutRef<'a'>, ref: Ref<HTMLAnchorElement>) => {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a target="_blank" rel="noreferrer" {...props} ref={ref} />;
  },
);
