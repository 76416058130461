import { ComponentType } from 'react';

import HexagonWarning from '@uikit/icons/HexagonWarning';
import MultiColorCheckmark from '@uikit/icons/MultiColorCheckmark';
import MultiColorTimePasses from '@uikit/icons/MultiColorTimePasses';
import MultiColorVote from '@uikit/icons/MultiColorVote';
import ReadOnly from '@uikit/icons/ReadOnly';

import { NEW_VALIDATION_STATUS, OLD_VALIDATION_STATUS } from '../constants/projectValidation';

export const APP_STATUS = {
  VALIDATED: 'VALIDATED',
  UPCOMING: 'UPCOMING',
  UNDER_VALIDATION: 'UNDER_VALIDATION',
  UNVALIDATED: 'UNVALIDATED',
  READ_ONLY: 'READ_ONLY',
} as const;

export type AppStatus = keyof typeof APP_STATUS;

export type AppStatusValue = (typeof APP_STATUS)[AppStatus];

export const appStatusConfig: Record<
  AppStatusValue,
  {
    Icon: ComponentType<{ className?: string }>;
    label: string;
  }
> = {
  [APP_STATUS.UPCOMING]: {
    Icon: MultiColorTimePasses,
    label: 'Upcoming',
  },
  [APP_STATUS.VALIDATED]: {
    Icon: MultiColorCheckmark,
    label: 'Validated',
  },
  [APP_STATUS.UNDER_VALIDATION]: {
    Icon: MultiColorVote,
    label: 'Under Validation',
  },
  [APP_STATUS.UNVALIDATED]: {
    Icon: HexagonWarning,
    label: 'Unvalidated',
  },
  [APP_STATUS.READ_ONLY]: {
    Icon: ReadOnly,
    label: 'Preview only',
  },
};

const appStatusToTextColorClassName: Record<AppStatusValue, string> = {
  [APP_STATUS.UNDER_VALIDATION]: 'text-orange-700',
  [APP_STATUS.VALIDATED]: 'text-secondary-700',
  [APP_STATUS.UPCOMING]: 'text-primary-700',
  [APP_STATUS.UNVALIDATED]: 'text-red-600',
  [APP_STATUS.READ_ONLY]: 'text-neutral-500',
};

export const getAppStatusConfig = (appStatus?: AppStatusValue | null) => {
  return appStatus ? appStatusConfig[appStatus] : null;
};

export const getAppStatusTextColorClassName = (appStatus: AppStatusValue) => {
  return appStatusToTextColorClassName[appStatus];
};

export const getAppStatus = ({
  productionReady = false,
  status = '',
}: { productionReady?: boolean; status?: string } = {}): AppStatusValue | null => {
  if (!productionReady) {
    return APP_STATUS.UPCOMING;
  }

  if (
    [
      NEW_VALIDATION_STATUS.EXTERNAL_VALIDATION_SUCCESS,
      NEW_VALIDATION_STATUS.INTERNAL_VALIDATION_SUCCESS,
      OLD_VALIDATION_STATUS.DONE,
    ].includes(status)
  ) {
    return APP_STATUS.VALIDATED;
  }

  if (
    [
      NEW_VALIDATION_STATUS.EXTERNAL_VALIDATION_FAILED_BAD_SCORE,
      NEW_VALIDATION_STATUS.EXTERNAL_VALIDATION_FAILED_NO_QUORUM,
      OLD_VALIDATION_STATUS.FAILED,
    ].includes(status)
  ) {
    return APP_STATUS.UNVALIDATED;
  }

  if (
    [
      NEW_VALIDATION_STATUS.EXTERNAL_VALIDATION,
      OLD_VALIDATION_STATUS.WAITING_FOR_STAKE,
      OLD_VALIDATION_STATUS.VALIDATORS_ADDED,
      OLD_VALIDATION_STATUS.WAITING_FOR_VOTE_START,
      OLD_VALIDATION_STATUS.CREATED,
      OLD_VALIDATION_STATUS.ROUND_STARTED,
    ].includes(status)
  ) {
    return APP_STATUS.UNDER_VALIDATION;
  }

  if ([NEW_VALIDATION_STATUS.READ_ONLY].includes(status)) {
    return APP_STATUS.READ_ONLY;
  }

  return null;
};
